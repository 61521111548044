<template>
  <div class="table-responsive m-t-40" style="clear: both">
    <template v-if="isMobile == false">
      <div class="col-md-12 col-sm-12">
        <div class="pull-left">
          <select
            v-model="selectLimit"
            class="form-control"
            @change="doUpdateWhenLimit"
            id="exampleSelect1"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <!-- <div class="pull-left">
          <select
            class="form-control"
            v-model="from"
            id="exampleSelect1"
            @change="getFrom"
          >
            <option value="me">Diri Sendiri</option>
            <option value="all">Satu Unit</option>
            <option value="pimpinan">Atensi Pimpinan</option>
            <option value="belum_dispo">Belum Didisposisi</option>
            <option value="sudah_dispo">Sudah Didisposisi</option>
          </select>
        </div> -->
        <!-- <div class="pull-right">
          <form @submit.prevent="doUpdate">
            <div class="input-group mb-3">
              <input
                v-model="search"
                type="text"
                class="form-control"
                placeholder="Search"
              />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="submit">
                  <i class="mdi mdi-magnify"></i>
                </button>
              </div>
            </div>
          </form>
        </div> -->
      </div>
      <!-- {{ isAksi }} -->
      <table class="table table-hover table-striped table-bordered">
        <thead v-if="isAksi == true" class="table-info">
          <tr>
            <th class="text-center">No</th>
            <!-- <th class="text-center" v-if="isContainFile">File</th> -->
            <th
              style="min-width: 150px"
              class="text-center"
              v-if="
                (!!onDetail && !forbidDetail) ||
                (!!onDetailTL && !forbidDetailTL) ||
                (!!onDetailTLI && !forbidDetailTLI) ||
                (!!onBerkas && !forbidBerkas) ||
                (!!onBerkaskan && !forbidBerkaskan) ||
                (!!onMoveBerkas && !forbidMoveBerkas) ||
                (!!onAtasan && !forbidAtasan) ||
                (!!onEdit && !forbidEdit) ||    
                (!!onApprove && !forbidApprove) ||
                (!!onBatalPesan && !forbidBatalPesan) ||             
                (!!onPemesanan && !forbidPemesanan) ||
                (!!onUsul && !forbidUsul) ||
                (!!onLaporan && !forbidLaporan) ||
                (!!onBatal && !forbidBatal) ||
                (!!onKonfirmasi && !forbidKonfirmasi) ||
                (!!onDisposisi && !forbidDisposisi) ||
                forbidDelete
              "
            >
              {{ $t("action") }}
            </th>
            <th v-for="(header, index) in header" :key="index">
              {{ header.label }}
            </th>
            <th class="text-center" v-if="isLaporan === true">Hasil Tindaklanjut</th>
          </tr>
        </thead>
        <thead v-else class="table-info">
          <tr>
            <th class="text-center">No</th>
            <th v-for="(header, index) in header" :key="index">
              {{ header.label }}
            </th>
            <th class="text-center" v-if="isLaporan === true">Hasil Tindaklanjut</th>
          </tr>
        </thead>
        <tbody v-if="isAksi == true">
          <template>
            <tr v-for="(value, indexOf) in computedItem" :key="indexOf">
              <!-- <h3>{{ value }}</h3> -->

              <!-- <td class="text-center" v-if="value['kecepatan'] === 4 " style="background-color: #ff9933;">
                  {{ value.increment }}
              </td>
              <td class="text-center" v-else-if="value['flag_atasan'] === 1" style="background-color: #26a7d1">
                {{ value.increment }}
              </td>
              <td class="text-center" v-else>
                {{ value.increment }}
              </td> -->
              <td
                class="text-center"
                v-if="value['kecepatan'] === 1 || value['kecepatan'] === 2"
                style="background-color: red"
              >
                {{ value.increment }}
              </td>
              <!-- <td
                class="text-center"
                v-if="value['kecepatan'] !== 1 || value['kecepatan'] !== 2"
              >
                {{ value.increment }}
              </td> -->
              <td class="text-center" v-else>
                {{ value.increment }}
              </td>
              <!-- <td v-if="isContainFile">
                <button
                  disabled
                  aria-disabled="true"
                  v-if="isContainFile"
                  v-bind:class="{
                    'btn disabled btn-sm btn-default': !value.file,
                    'btn disabled btn-sm btn-warning': value.file,
                  }"
                  style="color: #fff; cursor: default"
                >
                  <i class="mdi mdi-file-check" v-if="value.file"></i>
                  <i class="mdi mdi-file-excel" v-else></i>
                </button>
              </td> -->
              
              <td
                v-if="
                  !!onDetail == true ||
                  (!!onCancel && !forbidCancel) ||
                  (!!onDetail && !forbidDetail) ||
                  (!!onDetailTL && !forbidDetailTL) ||
                  (!!onDetailTLI && !forbidDetailTLI) ||
                  (!!onBerkas && !forbidBerkas) ||
                  (!!onBerkaskan && !forbidBerkaskan) ||
                  (!!onMoveBerkas && !forbidMoveBerkas) ||
                  (!!onAtasan && !forbidAtasan) ||
                  (!!onEdit && !forbidEdit) ||
                  (!!onApprove && !forbidApprove) ||
                  (!!onBatalPesan && !forbidBatalPesan) ||
                  (!!onPemesanan && !forbidPemesanan) ||
                  (!!onUsul && !forbidUsul) ||
                  (!!onLaporan && !forbidLaporan) ||
                  (!!onBatal && !forbidBatal) ||
                  (!!onKonfirmasi && !forbidKonfirmasi) ||
                  (!!onDisposisi && !forbidDisposisi) ||
                  forbidDelete
                "
                class="text-center"
              >
                

                <button
                  v-if="!!onDetail && !forbidDetail"
                  @click="onDetail(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Detail Data"
                >
                  <i class="mdi mdi-magnify"></i>
                </button>

                <!-- <button
                  v-else
                  @click="onDetail(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Detail Data"
                >
                  <i class="mdi mdi-magnify"></i>
                </button> -->

                <button
                  v-if="!!onBerkas && !forbidBerkas"
                  @click="onBerkas(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Detail Berkas"
                >
                  <i class="mdi mdi-send"></i>
                </button>

                <button
                  v-if="
                    value['ket'] == 1 && !!onBerkaskan && !forbidBerkaskan
                  "
                  @click="onBerkaskan(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Berkaskan Surat"
                >
                  <i class="mdi mdi-folder-lock"></i>
                </button>

                <button
                  v-if="
                    value['unitps'] == '1' &&
                    !!onMoveBerkas &&
                    !forbidMoveBerkas
                  "
                  @click="onMoveBerkas(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Move Berkas"
                >
                  <i class="mdi mdi-folder-move"></i>
                </button>

                <button
                  v-if="
                    value['flag_atasan'] < '1' && !!onAtasan && !forbidAtasan
                  "
                  @click="showConfirmAtasan(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Atasan"
                >
                  <i class="mdi mdi-account-star"></i>
                </button>

                <button
                  v-if="
                    value['canCancel'] === undefined &&
                    !!onCancel &&
                    !forbidCancel
                  "
                  @click="onCancel(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Cancel Data"
                >
                  <i class="mdi mdi-backup-restore"></i>
                </button>
                <button
                  v-else-if="
                    value['canCancel'] == true && !!onCancel && !forbidCancel
                  "
                  @click="onCancel(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Cancel Data"
                >
                  <i class="mdi mdi-backup-restore"></i>
                </button>
                <button
                  v-if="
                    value['canEdit'] === undefined && !!onEdit && !forbidEdit
                  "
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <button
                  v-if="!!onPemesanan && !forbidPemesanan"
                  @click="onPemesanan(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Pemesanan Ruangan Meeting"
                >
                  <i class="mdi mdi-near-me"></i>
                </button>
                <button
                  v-if="!!onUsul && !forbidUsul"
                  @click="onUsul(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Surat Diusulkan ke Proses Selanjutnya"
                >
                  <i class="mdi mdi-forward"></i>
                </button>
                <button
                  v-if="value['stat'] === 0 && value['canApprove'] === 1 && !!onApprove && !forbidApprove"
                  @click="onApprove(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Approve Meeting Room"
                >
                  <i class="mdi mdi-star"></i>
                </button>
                <button
                  v-if="value['stat'] === 0 && value['isMeeting'] === true && value['canBatal'] == true && !!onBatalPesan && !forbidBatalPesan"
                  @click="onBatalPesan(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Batal Meeting Room"
                >
                  <i class="mdi mdi-close"></i>
                </button>
                <button
                  v-if="value['stat'] === 0 && value['isMeeting'] === false && value['canBatal'] == true && !!onBatalPesan && !forbidBatalPesan"
                  @click="onBatalPesan(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Batal Meeting Room"
                >
                  <i class="mdi mdi-close"></i>
                </button>
                <button
                  v-if="value['stat'] === 1 && value['isMeeting'] === true && value['canBatal'] == true && !!onBatalPesan && !forbidBatalPesan"
                  @click="onBatalPesan(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Batal Meeting Room"
                >
                  <i class="mdi mdi-close"></i>
                </button>
                <button
                  v-if="value['isLaporan'] == true && !!onLaporan && !forbidLaporan"
                  @click="onLaporan(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Laporan Agenda"
                >
                  <i class="mdi mdi-account-edit"></i>
                </button>
                <button
                  v-if="
                    value['canEdit'] == true && !!onEdit && !forbidEdit
                  "
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <button
                  v-else-if="value['canEdit'] == true"
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>

                <button
                  v-if="
                    value['canKonfirmasi'] === undefined &&
                    !!onKonfirmasi &&
                    !forbidEdit
                  "
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button>
                <button
                  v-else-if="
                    value['canKonfirmasi'] == true &&
                    !!onKonfirmasi &&
                    !forbidEdit
                  "
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button>

                <button
                  v-if="
                    value['canDisposisi'] === undefined &&
                    !!onDisposisi &&
                    !forbidEdit
                  "
                  @click="onDisposisi(value)"
                  class="btn btn-sm btn-secondary mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-account"></i>
                </button>
                <button
                  v-else-if="
                    value['canDisposisi'] == true &&
                    !!onDisposisi &&
                    !forbidEdit
                  "
                  @click="onDisposisi(value)"
                  class="btn btn-sm btn-secondary mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-account"></i>
                </button>
                <button
                v-if="value['canEdit'] == true && forbidDelete == true || value['deleteable'] == true"
                  :disabled="disableDeleteButton[indexOf]"
                  @click="showConfirmDelete(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Hapus Data"
                >
                  <i class="mdi mdi-delete"></i>
                </button>
              </td>

              <td
                v-for="(header, index) in header"
                :key="index"
                data-toogle="tooltip"
                :title="value[header.tooltip]"
              >
                <div
                  v-if="
                    value['is_read_recipient'] === 1 ||
                    value['is_read_personal'] === 1
                  "
                >
                  {{ header.condition ? haveCondition(indexOf) : null }}
                  <div
                    v-if="
                      header.is_read &&
                      (value['is_read_recipient'] === 1 ||
                        value['is_read_personal'] === 1)
                    "
                  >
                    <input type="checkbox" checked disabled />
                  </div>
                  <div
                    :style="fontStyle"
                    v-bind:class="{ 'bold-td': value.isBold }"
                    v-else
                  >
                    <div v-if="header.type === 'date'">
                      {{ formatDate(value[index]) }}
                    </div>
                    <div v-else-if="header.type === 'dateTime'">
                      {{ formatDateTime(value[index]) }}
                    </div>
                    <div v-else>
                      <span v-html="value[index]"></span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ header.condition ? haveCondition(indexOf) : null }}
                  <div
                    v-if="
                      header.is_read &&
                      (value['is_read_recipient'] === 0 ||
                        value['is_read_personal'] === 0)
                    "
                  >
                    <input type="checkbox" disabled />
                  </div>
                  <div
                    :style="fontStyle"
                    v-bind:class="{ 'bold-td': value.isBold }"
                    v-else
                  >
                    <div v-if="header.type === 'date'">
                      {{ formatDate(value[index]) }}
                    </div>
                    <div v-else-if="header.type === 'dateTime'">
                      {{ formatDateTime(value[index]) }}
                    </div>
                    <div v-else>
                      <span v-html="value[index]"></span>
                    </div>
                  </div>
                </div>
              </td>

              <!-- Hasil Tindak Lanjut -->
              <td class="text-center" v-if="isLaporan">
                <!-- Hasil Tindaklanjut -->
                <span v-if="value.notif == true" style="color: red">
                  <!-- {{ value.notif }} -->
                  <i class="mdi mdi-account-alert"></i> </span
                >&nbsp;

                <!-- (!!onDetailTL &&
                      !forbidDetailTL &&
                      value.hasil == true &&
                      value.kembali == false &&
                      value.surat_masuk == false) ||
                    value.surat_masuk == true -->
                <button
                  @click="onDetailTL(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Detail Data"
                >
                  <i class="mdi mdi-magnify"></i>
                </button>

                <!-- Tindaklanjut -->
                <!-- (value.btl == true && value.responsedispo == 0) || -->
                <!-- value.sendiri == true -->
                <button
                  v-if="value.btl == true && value.responsedispo == 0"
                  @click="onDetailTLI(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Input Tindak Lanjut"
                >
                  <!-- v-if="!!onDetailTLI && !forbidDetailTLI && value.btl == true" -->
                  <!-- v-if="
                    (!!onDetailTLI && !forbidDetailTLI) ||
                    (value.hasil == true &&
                      value.kembali == false &&
                      value.btl == true)
                  " -->
                  <i class="mdi mdi-clipboard-check"></i>
                  <!-- {{ value.tindaklanjut }} {{ value.kembali }} {{ value.btl }} -->
                </button>
                <!-- v-if="
                    !!onDetailTLI &&
                    !forbidDetailTLI &&
                    value.hasil == true &&
                    value.kembali == false &&
                    value.btl == true
                  " -->
                <!-- value.tindaklanjut == true &&
                    value.kembali == false &&
                    value.btl == true -->

                <!-- <span
                  >{{ value.hasil }} {{ value.kembali }} {{ value.btl }}</span
                > -->

                <!-- <h4>{{ value.tindaklanjut }}</h4> -->
              </td>
              <!-- Hasil TIndak Lanjut -->
            </tr>

            <tr v-if="items.length === 0">
              <td :colspan="Object.keys(header).length + 2" class="text-center">
                No record found
              </td>
            </tr>
          </template>
        </tbody>
        <tbody v-else>
          <template>
            <tr v-for="(value, indexOf) in computedItem" :key="indexOf">
              <td
                class="text-center"
                v-if="value['kecepatan'] === 1 || value['kecepatan'] === 2"
                style="background-color: red"
              >
                {{ value.increment }}
              </td>
              <td class="text-center" v-else>
                {{ value.increment }}
              </td>
              <td
                v-for="(header, index) in header"
                :key="index"
                data-toogle="tooltip"
                :title="value[header.tooltip]"
              >
                <div
                  v-if="
                    value['is_read_recipient'] === 1 ||
                    value['is_read_personal'] === 1
                  "
                >
                  {{ header.condition ? haveCondition(indexOf) : null }}
                  <div
                    v-if="
                      header.is_read &&
                      (value['is_read_recipient'] === 1 ||
                        value['is_read_personal'] === 1)
                    "
                  >
                    <input type="checkbox" checked disabled />
                  </div>
                  <div
                    :style="fontStyle"
                    v-bind:class="{ 'bold-td': value.isBold }"
                    v-else
                  >
                    <div v-if="header.type === 'date'">
                      {{ formatDate(value[index]) }}
                    </div>
                    <div v-else-if="header.type === 'dateTime'">
                      {{ formatDateTime(value[index]) }}
                    </div>
                    <div v-else>
                      <span v-html="value[index]"></span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ header.condition ? haveCondition(indexOf) : null }}
                  <div
                    v-if="
                      header.is_read &&
                      (value['is_read_recipient'] === 0 ||
                        value['is_read_personal'] === 0)
                    "
                  >
                    <input type="checkbox" disabled />
                  </div>
                  <div
                    :style="fontStyle"
                    v-bind:class="{ 'bold-td': value.isBold }"
                    v-else
                  >
                    <div v-if="header.type === 'date'">
                      {{ formatDate(value[index]) }}
                    </div>
                    <div v-else-if="header.type === 'dateTime'">
                      {{ formatDateTime(value[index]) }}
                    </div>
                    <div v-else>
                      <span v-html="value[index]"></span>
                    </div>
                  </div>
                </div>
              </td>

              <!-- Hasil Tindak Lanjut -->
              <td class="text-center" v-if="isLaporan">
                <!-- Hasil Tindaklanjut -->
                <span v-if="value.notif == true" style="color: red">
                  <!-- {{ value.notif }} -->
                  <i class="mdi mdi-account-alert"></i> </span
                >&nbsp;

                <!-- (!!onDetailTL &&
                      !forbidDetailTL &&
                      value.hasil == true &&
                      value.kembali == false &&
                      value.surat_masuk == false) ||
                    value.surat_masuk == true -->
                <button
                  @click="onDetailTL(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Detail Data"
                >
                  <i class="mdi mdi-magnify"></i>
                </button>

                <!-- Tindaklanjut -->
                <!-- (value.btl == true && value.responsedispo == 0) || -->
                <!-- value.sendiri == true -->
                <button
                  v-if="value.btl == true && value.responsedispo == 0"
                  @click="onDetailTLI(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Input Tindak Lanjut"
                >
                  <!-- v-if="!!onDetailTLI && !forbidDetailTLI && value.btl == true" -->
                  <!-- v-if="
                    (!!onDetailTLI && !forbidDetailTLI) ||
                    (value.hasil == true &&
                      value.kembali == false &&
                      value.btl == true)
                  " -->
                  <i class="mdi mdi-clipboard-check"></i>
                  <!-- {{ value.tindaklanjut }} {{ value.kembali }} {{ value.btl }} -->
                </button>
                <!-- v-if="
                    !!onDetailTLI &&
                    !forbidDetailTLI &&
                    value.hasil == true &&
                    value.kembali == false &&
                    value.btl == true
                  " -->
                <!-- value.tindaklanjut == true &&
                    value.kembali == false &&
                    value.btl == true -->

                <!-- <span
                  >{{ value.hasil }} {{ value.kembali }} {{ value.btl }}</span
                > -->

                <!-- <h4>{{ value.tindaklanjut }}</h4> -->
              </td>
              <!-- Hasil TIndak Lanjut -->
            </tr>

            <tr v-if="items.length === 0">
              <td :colspan="Object.keys(header).length + 2" class="text-center">
                No record found
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="col-md-12">
        <div class="pull-left">
          <p>
            <i
              >limit {{ selectLimit }} of {{ filter.totalRecords }} total
              record</i
            >
          </p>
        </div>
        <div class="pull-right">
          <Paginate
            :page="filter.page"
            :totalPage="filter.totalPages"
            @onChangePage="updatePagination"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isRuangan === true && isMobile === true">
      <div class="col-md-12 col-sm-12">
        <div class="pull-left">
          <select
            v-model="selectLimit"
            class="form-control"
            @change="doUpdateWhenLimit"
            id="exampleSelect1"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      </div>
      <table class="table table-hover table-striped table-bordered">
        <thead class="table-info">
          <tr>
            <th class="text-center">No</th>
            <!-- <th class="text-center" v-if="isContainFile">File</th> -->
            <th
              style="min-width: 150px"
              class="text-center"
              v-if="
                (!!onDetail && !forbidDetail) ||
                (!!onDetailTL && !forbidDetailTL) ||
                (!!onDetailTLI && !forbidDetailTLI) ||
                (!!onBerkas && !forbidBerkas) ||
                (!!onBerkaskan && !forbidBerkaskan) ||
                (!!onMoveBerkas && !forbidMoveBerkas) ||
                (!!onAtasan && !forbidAtasan) ||
                (!!onEdit && !forbidEdit) ||    
                (!!onApprove && !forbidApprove) ||
                (!!onBatalPesan && !forbidBatalPesan) ||             
                (!!onPemesanan && !forbidPemesanan) ||
                (!!onUsul && !forbidUsul) ||
                (!!onLaporan && !forbidLaporan) ||
                (!!onBatal && !forbidBatal) ||
                (!!onKonfirmasi && !forbidKonfirmasi) ||
                (!!onDisposisi && !forbidDisposisi) ||
                forbidDelete
              "
            >
              {{ $t("action") }}
            </th>
            <th v-for="(header, index) in header" :key="index">
              {{ header.label }}
            </th>
            <th class="text-center" v-if="isLaporan === true">Hasil Tindaklanjut</th>
          </tr>
        </thead>
        <tbody>
          <template>
            <tr v-for="(value, indexOf) in computedItem" :key="indexOf">
              <!-- <h3>{{ value }}</h3> -->

              <!-- <td class="text-center" v-if="value['kecepatan'] === 4 " style="background-color: #ff9933;">
                  {{ value.increment }}
              </td>
              <td class="text-center" v-else-if="value['flag_atasan'] === 1" style="background-color: #26a7d1">
                {{ value.increment }}
              </td>
              <td class="text-center" v-else>
                {{ value.increment }}
              </td> -->
              <td
                class="text-center"
                v-if="value['kecepatan'] === 1 || value['kecepatan'] === 2"
                style="background-color: red"
              >
                {{ value.increment }}
              </td>
              <!-- <td
                class="text-center"
                v-if="value['kecepatan'] !== 1 || value['kecepatan'] !== 2"
              >
                {{ value.increment }}
              </td> -->
              <td class="text-center" v-else>
                {{ value.increment }}
              </td>
              <!-- <td v-if="isContainFile">
                <button
                  disabled
                  aria-disabled="true"
                  v-if="isContainFile"
                  v-bind:class="{
                    'btn disabled btn-sm btn-default': !value.file,
                    'btn disabled btn-sm btn-warning': value.file,
                  }"
                  style="color: #fff; cursor: default"
                >
                  <i class="mdi mdi-file-check" v-if="value.file"></i>
                  <i class="mdi mdi-file-excel" v-else></i>
                </button>
              </td> -->
              <td
                v-if="
                  (!!onCancel && !forbidCancel) ||
                  (!!onDetail && !forbidDetail) ||
                  (!!onDetailTL && !forbidDetailTL) ||
                  (!!onDetailTLI && !forbidDetailTLI) ||
                  (!!onBerkas && !forbidBerkas) ||
                  (!!onBerkaskan && !forbidBerkaskan) ||
                  (!!onMoveBerkas && !forbidMoveBerkas) ||
                  (!!onAtasan && !forbidAtasan) ||
                  (!!onEdit && !forbidEdit) ||
                  (!!onApprove && !forbidApprove) ||
                  (!!onBatalPesan && !forbidBatalPesan) ||
                  (!!onPemesanan && !forbidPemesanan) ||
                  (!!onUsul && !forbidUsul) ||
                  (!!onLaporan && !forbidLaporan) ||
                  (!!onBatal && !forbidBatal) ||
                  (!!onKonfirmasi && !forbidKonfirmasi) ||
                  (!!onDisposisi && !forbidDisposisi) ||
                  forbidDelete
                "
                class="text-center"
              >
                

                <button
                  v-if="!!onDetail && !forbidDetail"
                  @click="onDetail(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Detail Data"
                >
                  <i class="mdi mdi-magnify"></i>
                </button>

                <button
                  v-else
                  @click="onDetail(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Detail Data"
                >
                  <i class="mdi mdi-magnify"></i>
                </button>

                <button
                  v-if="!!onBerkas && !forbidBerkas"
                  @click="onBerkas(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Detail Berkas"
                >
                  <i class="mdi mdi-send"></i>
                </button>

                <button
                  v-if="
                    value['ket'] == 1 && !!onBerkaskan && !forbidBerkaskan
                  "
                  @click="onBerkaskan(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Berkaskan Surat"
                >
                  <i class="mdi mdi-folder-lock"></i>
                </button>

                <button
                  v-if="
                    value['unitps'] == '1' &&
                    !!onMoveBerkas &&
                    !forbidMoveBerkas
                  "
                  @click="onMoveBerkas(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Move Berkas"
                >
                  <i class="mdi mdi-folder-move"></i>
                </button>

                <button
                  v-if="
                    value['flag_atasan'] < '1' && !!onAtasan && !forbidAtasan
                  "
                  @click="showConfirmAtasan(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Atasan"
                >
                  <i class="mdi mdi-account-star"></i>
                </button>

                <button
                  v-if="
                    value['canCancel'] === undefined &&
                    !!onCancel &&
                    !forbidCancel
                  "
                  @click="onCancel(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Cancel Data"
                >
                  <i class="mdi mdi-backup-restore"></i>
                </button>
                <button
                  v-else-if="
                    value['canCancel'] == true && !!onCancel && !forbidCancel
                  "
                  @click="onCancel(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Cancel Data"
                >
                  <i class="mdi mdi-backup-restore"></i>
                </button>
                <button
                  v-if="
                    value['canEdit'] === undefined && !!onEdit && !forbidEdit
                  "
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <button
                  v-if="!!onPemesanan && !forbidPemesanan"
                  @click="onPemesanan(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Pemesanan Ruangan Meeting"
                >
                  <i class="mdi mdi-near-me"></i>
                </button>
                <button
                  v-if="!!onUsul && !forbidUsul"
                  @click="onUsul(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Surat Diusulkan ke Proses Selanjutnya"
                >
                  <i class="mdi mdi-forward"></i>
                </button>
                <button
                  v-if="value['stat'] === 0 && value['canApprove'] === 1 && !!onApprove && !forbidApprove"
                  @click="onApprove(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Approve Meeting Room"
                >
                  <i class="mdi mdi-star"></i>
                </button>
                <button
                  v-if="value['stat'] === 0 && !!onBatalPesan && !forbidBatalPesan"
                  @click="onBatalPesan(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Batal Meeting Room"
                >
                  <i class="mdi mdi-close"></i>
                </button>
                <button
                  v-if="
                    value['canEdit'] === true && !!onLaporan && !forbidLaporan
                  "
                  @click="onLaporan(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Laporan Agenda"
                >
                  <i class="mdi mdi-account-edit"></i>
                </button>
                <button
                  v-else-if="
                    value['canEdit'] == true && !!onEdit && !forbidEdit
                  "
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <button
                  v-else-if="value['canEdit'] == true"
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>

                <button
                  v-if="
                    value['canKonfirmasi'] === undefined &&
                    !!onKonfirmasi &&
                    !forbidEdit
                  "
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button>
                <button
                  v-else-if="
                    value['canKonfirmasi'] == true &&
                    !!onKonfirmasi &&
                    !forbidEdit
                  "
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button>

                <button
                  v-if="
                    value['canDisposisi'] === undefined &&
                    !!onDisposisi &&
                    !forbidEdit
                  "
                  @click="onDisposisi(value)"
                  class="btn btn-sm btn-secondary mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-account"></i>
                </button>
                <button
                  v-else-if="
                    value['canDisposisi'] == true &&
                    !!onDisposisi &&
                    !forbidEdit
                  "
                  @click="onDisposisi(value)"
                  class="btn btn-sm btn-secondary mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-account"></i>
                </button>
                <button
                v-if="value['canEdit'] == true && !forbidDelete"
                  :disabled="disableDeleteButton[indexOf]"
                  @click="showConfirmDelete(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Hapus Data"
                >
                  <i class="mdi mdi-delete"></i>
                </button>
              </td>

              <td
                v-for="(header, index) in header"
                :key="index"
                data-toogle="tooltip"
                :title="value[header.tooltip]"
              >
                <div
                  v-if="
                    value['is_read_recipient'] === 1 ||
                    value['is_read_personal'] === 1
                  "
                >
                  {{ header.condition ? haveCondition(indexOf) : null }}
                  <div
                    v-if="
                      header.is_read &&
                      (value['is_read_recipient'] === 1 ||
                        value['is_read_personal'] === 1)
                    "
                  >
                    <input type="checkbox" checked disabled />
                  </div>
                  <div
                    :style="fontStyle"
                    v-bind:class="{ 'bold-td': value.isBold }"
                    v-else
                  >
                    <div v-if="header.type === 'date'">
                      {{ formatDate(value[index]) }}
                    </div>
                    <div v-else-if="header.type === 'dateTime'">
                      {{ formatDateTime(value[index]) }}
                    </div>
                    <div v-else>
                      <span v-html="value[index]"></span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ header.condition ? haveCondition(indexOf) : null }}
                  <div
                    v-if="
                      header.is_read &&
                      (value['is_read_recipient'] === 0 ||
                        value['is_read_personal'] === 0)
                    "
                  >
                    <input type="checkbox" disabled />
                  </div>
                  <div
                    :style="fontStyle"
                    v-bind:class="{ 'bold-td': value.isBold }"
                    v-else
                  >
                    <div v-if="header.type === 'date'">
                      {{ formatDate(value[index]) }}
                    </div>
                    <div v-else-if="header.type === 'dateTime'">
                      {{ formatDateTime(value[index]) }}
                    </div>
                    <div v-else>
                      <span v-html="value[index]"></span>
                    </div>
                  </div>
                </div>
              </td>

              <!-- Hasil Tindak Lanjut -->
              <td class="text-center" v-if="isLaporan">
                <!-- Hasil Tindaklanjut -->
                <span v-if="value.notif == true" style="color: red">
                  <!-- {{ value.notif }} -->
                  <i class="mdi mdi-account-alert"></i> </span
                >&nbsp;

                <!-- (!!onDetailTL &&
                      !forbidDetailTL &&
                      value.hasil == true &&
                      value.kembali == false &&
                      value.surat_masuk == false) ||
                    value.surat_masuk == true -->
                <button
                  @click="onDetailTL(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Detail Data"
                >
                  <i class="mdi mdi-magnify"></i>
                </button>

                <!-- Tindaklanjut -->
                <!-- (value.btl == true && value.responsedispo == 0) || -->
                <!-- value.sendiri == true -->
                <button
                  v-if="value.btl == true && value.responsedispo == 0"
                  @click="onDetailTLI(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Input Tindak Lanjut"
                >
                  <!-- v-if="!!onDetailTLI && !forbidDetailTLI && value.btl == true" -->
                  <!-- v-if="
                    (!!onDetailTLI && !forbidDetailTLI) ||
                    (value.hasil == true &&
                      value.kembali == false &&
                      value.btl == true)
                  " -->
                  <i class="mdi mdi-clipboard-check"></i>
                  <!-- {{ value.tindaklanjut }} {{ value.kembali }} {{ value.btl }} -->
                </button>
                <!-- v-if="
                    !!onDetailTLI &&
                    !forbidDetailTLI &&
                    value.hasil == true &&
                    value.kembali == false &&
                    value.btl == true
                  " -->
                <!-- value.tindaklanjut == true &&
                    value.kembali == false &&
                    value.btl == true -->

                <!-- <span
                  >{{ value.hasil }} {{ value.kembali }} {{ value.btl }}</span
                > -->

                <!-- <h4>{{ value.tindaklanjut }}</h4> -->
              </td>
              <!-- Hasil TIndak Lanjut -->
            </tr>

            <tr v-if="items.length === 0">
              <td :colspan="Object.keys(header).length + 2" class="text-center">
                No record found
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="col-md-12">
        <div class="pull-left">
          <p>
            <i
              >limit {{ selectLimit }} of {{ filter.totalRecords }} total
              record</i
            >
          </p>
        </div>
        <div class="pull-right">
          <Paginate
            :page="filter.page"
            :totalPage="filter.totalPages"
            @onChangePage="updatePagination"
          />
        </div>
      </div>
    </template>
    <template v-else-if="isMobile === true && isRuangan === false">
      <div>
        <div class="pull-right">
          <form @submit.prevent="doUpdate">
            <div class="input-group mb-3">
              <input
                v-model="search"
                type="text"
                class="form-control"
                placeholder="Search"
              />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="submit">
                  <i class="mdi mdi-magnify"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <table class="table table-hover">
        <thead class="table-info">
          <tr>
            <th class="text-center">No</th>
            <th>Aksi</th>
            <th>{{ Object.values(header)[0].label }}</th>
            <th>{{ Object.values(header)[3].label }}</th>
          </tr>
        </thead>
        <tbody>
          <template>
            <tr v-for="(value, indexOf) in computedItem" :key="indexOf">
              {{
                Object.values(header)[0].condition
                  ? haveCondition(indexOf)
                  : null
              }}
              <td class="text-center">{{ value.increment }}</td>
              <!-- <td class="text-center">
                <small>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onDetail && !forbidDetail"
                        @click="onDetail(value)"
                        ><i class="fa fa-folder"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onDetailTL && !forbidDetailTL"
                        @click="onDetailTL(value)"
                        ><i class="fa fa-folder-user"></i></a
                      >
                    </span>
                    <span class="col-md-4">
                      <a
                        v-if="!!onDetailTLI && !forbidDetailTLI"
                        @click="onDetailTLI(value)"
                        ><i class="fa fa-pencil-square-o"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onBerkas && !forbidBerkas"
                        @click="onBerkas(value)"
                        ><i class="fa fa-folder"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onBerkaskan && !forbidBerkaskan"
                        @click="onBerkaskan(value)"
                        >Lakukan Pemberkasan</a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onMoveBerkas && !forbidMoveBerkas"
                        @click="onMoveBerkas(value)"
                        ><i class="fa fa-folder"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onAtasan && !forbidAtasan"
                        @click="showConfirmAtasan(value)"
                        ><i class="fa fa-user-plus"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a v-if="!!onEdit && !forbidEdit" @click="onEdit(value)"
                        ><i class="fa fa-pencil"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onBatal && !forbidBatal"
                        @click="onBatal(value)"
                      >
                        Dibatalkan
                      </a>
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onCancel && !forbidCancel"
                        @click="onCancel(value)"
                        >Cancel</a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onKonfirmasi && !forbidKonfirmasi"
                        @click="onKonfirmasi(value)"
                        >Konfirmasi</a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onDisposisi && !forbidDisposisi"
                        @click="onDisposisi(value)"
                        >Disposisi</a
                      >
                    </span>
                    <span
                      class="col-sm-4"
                      style="color: red"
                      v-show="!disableDeleteButton[indexOf]"
                    >
                      <a v-if="!forbidDelete" @click="showConfirmDelete(value)"
                        >Delete</a
                      >
                    </span>
                  </small>
              </td> -->

              <td
                v-if="
                  (!!onCancel && !forbidCancel) ||
                  (!!onDetail && !forbidDetail) ||
                  (!!onDetailTL && !forbidDetailTL) ||
                  (!!onDetailTLI && !forbidDetailTLI) ||
                  (!!onBerkas && !forbidBerkas) ||
                  (!!onBerkaskan && !forbidBerkaskan) ||
                  (!!onMoveBerkas && !forbidMoveBerkas) ||
                  (!!onAtasan && !forbidAtasan) ||
                  (!!onEdit && !forbidEdit) ||
                  (!!onLaporan && !forbidLaporan) ||
                  (!!onBatal && !forbidBatal) ||
                  (!!onKonfirmasi && !forbidKonfirmasi) ||
                  (!!onDisposisi && !forbidDisposisi) ||
                  forbidDelete
                "
                class="text-center"
              >

                <button
                  v-if="!!onDetail && !forbidDetail"
                  @click="onDetail(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Detail Data"
                >
                  <i class="mdi mdi-magnify"></i>
                </button>

                <button
                  v-else
                  @click="onDetail(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Detail Data"
                >
                  <i class="mdi mdi-magnify"></i>
                </button>

                <button
                  v-if="value['unitps'] == '1' && !!onBerkas && !forbidBerkas"
                  @click="onBerkas(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Detail Berkas"
                >
                  <i class="mdi mdi-send"></i>
                </button>

                <button
                  v-if="
                    value['ket'] == 1 && !!onBerkaskan && !forbidBerkaskan
                  "
                  @click="onBerkaskan(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Berkaskan Surat"
                >
                  <i class="mdi mdi-folder-lock"></i>
                </button>

                <button
                  v-if="
                    value['unitps'] == '1' &&
                    !!onMoveBerkas &&
                    !forbidMoveBerkas
                  "
                  @click="onMoveBerkas(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Move Berkas"
                >
                  <i class="mdi mdi-folder-move"></i>
                </button>

                <button
                  v-if="
                    value['flag_atasan'] < '1' && !!onAtasan && !forbidAtasan
                  "
                  @click="showConfirmAtasan(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Atasan"
                >
                  <i class="mdi mdi-account-star"></i>
                </button>

                <!-- @click="onAtasan(value)" -->
                <!-- <button
                  v-if="!!onEdit && !forbidEdit && value['is_physical'] == '1' || value['status'] == '' || value['is_superior'] == '0'"
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button> -->

                <button
                  v-if="
                    value['canCancel'] === undefined &&
                    !!onCancel &&
                    !forbidCancel
                  "
                  @click="onCancel(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Cancel Data"
                >
                  <i class="mdi mdi-backup-restore"></i>
                </button>
                <button
                  v-else-if="
                    value['canCancel'] == true && !!onCancel && !forbidCancel
                  "
                  @click="onCancel(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Cancel Data"
                >
                  <i class="mdi mdi-backup-restore"></i>
                </button>
                <button
                  v-if="
                    value['canEdit'] === undefined && !!onEdit && !forbidEdit
                  "
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                {{  value['canEdit'] }}
                <button
                  v-if="
                    value['canEdit'] === true && !!onLaporan && !forbidLaporan
                  "
                  @click="onLaporan(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <!-- {{ value["canEdit"] }} -->

                <!-- v-if="
                    value['deleteable'] == true && !!onBatal && !forbidBatal
                  " -->
                <button
                  v-else-if="
                    value['canEdit'] == true && !!onEdit && !forbidEdit
                  "
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <button
                  v-else-if="value['canEdit'] == true"
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <!-- <button
                  v-else-if="
                    value['canEdit'] == true && !!onLaporan && !forbidLaporan
                  "
                  @click="onLaporan(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <button
                  v-else-if="value['canEdit'] == true"
                  @click="onLaporan(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button> -->
                <!-- <button
                  v-if="value['deleteable'] == true"
                  @click="onBatal(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Dibatalkan"
                >
                  <i class="fa fa-trash"></i>
                </button> -->
                <!-- <button
                  v-else
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button> -->
                <!-- <button
                  v-if="!!onEdit && !forbidEdit && value['status'] != ''"
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button> -->

                <button
                  v-if="
                    value['canKonfirmasi'] === undefined &&
                    !!onKonfirmasi &&
                    !forbidEdit
                  "
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button>
                <button
                  v-else-if="
                    value['canKonfirmasi'] == true &&
                    !!onKonfirmasi &&
                    !forbidEdit
                  "
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button>

                <button
                  v-if="
                    value['canDisposisi'] === undefined &&
                    !!onDisposisi &&
                    !forbidEdit
                  "
                  @click="onDisposisi(value)"
                  class="btn btn-sm btn-secondary mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-account"></i>
                </button>
                <button
                  v-else-if="
                    value['canDisposisi'] == true &&
                    !!onDisposisi &&
                    !forbidEdit
                  "
                  @click="onDisposisi(value)"
                  class="btn btn-sm btn-secondary mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-account"></i>
                </button>

                <!-- <button
                  v-if="value['canKonfirmasi']===undefined && (!!onKonfirmasi && !forbidKonfirmasi)"
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button>
                <button
                  v-else-if="value['canKonfirmasi']==true && (!!onKonfirmasi && !forbidKonfirmasi)"
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button> -->

                <!-- <button
                  v-if="value['konfirmasi'] != '1' && forbidKonfirmasi"
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Konfirmasi"
                >
                  <i class="mdi mdi-check"></i>
                </button> -->
                <!-- <button
                  v-if="value['konfirmasi'] === '1' && forbidDisposisi"
                  @click="onDisposisi(value)"
                  class="btn btn-sm btn-secondary mr-1"
                  title="Disposisi"
                >
                  <i class="mdi mdi-account"></i>
                </button> -->
                <button
                  v-show="!forbidDelete"
                  :disabled="disableDeleteButton[indexOf]"
                  @click="showConfirmDelete(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Hapus Data"
                >
                  <i class="mdi mdi-delete"></i>
                </button>
              </td>
              
              <td>
                <div v-if="header.type === 'date'">
                  {{ formatDate(value.name) }}
                </div>
                <div v-else-if="header.type === 'dateTime'">
                  {{ formatDateTime(value.name) }}
                </div>
                <div v-else>
                  <span v-html="value[Object.keys(header)[0]]"></span>                  
                </div>
              </td>
              <td><span v-html="value[Object.keys(header)[3]]"></span></td>
            </tr>
            <tr v-if="items.length === 0">
              <td :colspan="Object.keys(header).length + 2" class="text-center">
                No record found
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="col-md-12">
        <div class="pull-right">
          <Paginate
            :page="filter.page"
            :totalPage="filter.totalPages"
            @onChangePage="updatePagination"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div>
        <div class="pull-right">
          <form @submit.prevent="doUpdate">
            <div class="input-group mb-3">
              <input
                v-model="search"
                type="text"
                class="form-control"
                placeholder="Search"
              />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" type="submit">
                  <i class="mdi mdi-magnify"></i>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <table class="table table-hover">
        <thead class="table-info">
          <tr>
            <th class="text-center">No</th>
            <th>Aksi</th>
            <!-- <th>{{ Object.values(header)[0].label }}</th>
            <th>{{ Object.values(header)[3].label }}</th> -->
            <th v-for="(header, index) in header" :key="index">
              {{ header.label }}
            </th>

          </tr>
        </thead>
        <tbody>
          <template>
            <tr v-for="(value, indexOf) in computedItem" :key="indexOf">
              {{
                Object.values(header)[0].condition
                  ? haveCondition(indexOf)
                  : null
              }}
              <td class="text-center">{{ value.increment }}</td>
              <!-- <td class="text-center">
                <small>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onDetail && !forbidDetail"
                        @click="onDetail(value)"
                        ><i class="fa fa-folder"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onDetailTL && !forbidDetailTL"
                        @click="onDetailTL(value)"
                        ><i class="fa fa-folder-user"></i></a
                      >
                    </span>
                    <span class="col-md-4">
                      <a
                        v-if="!!onDetailTLI && !forbidDetailTLI"
                        @click="onDetailTLI(value)"
                        ><i class="fa fa-pencil-square-o"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onBerkas && !forbidBerkas"
                        @click="onBerkas(value)"
                        ><i class="fa fa-folder"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onBerkaskan && !forbidBerkaskan"
                        @click="onBerkaskan(value)"
                        >Lakukan Pemberkasan</a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onMoveBerkas && !forbidMoveBerkas"
                        @click="onMoveBerkas(value)"
                        ><i class="fa fa-folder"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onAtasan && !forbidAtasan"
                        @click="showConfirmAtasan(value)"
                        ><i class="fa fa-user-plus"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a v-if="!!onEdit && !forbidEdit" @click="onEdit(value)"
                        ><i class="fa fa-pencil"></i></a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onBatal && !forbidBatal"
                        @click="onBatal(value)"
                      >
                        Dibatalkan
                      </a>
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onCancel && !forbidCancel"
                        @click="onCancel(value)"
                        >Cancel</a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onKonfirmasi && !forbidKonfirmasi"
                        @click="onKonfirmasi(value)"
                        >Konfirmasi</a
                      >
                    </span>
                    <span class="col-sm-4">
                      <a
                        v-if="!!onDisposisi && !forbidDisposisi"
                        @click="onDisposisi(value)"
                        >Disposisi</a
                      >
                    </span>
                    <span
                      class="col-sm-4"
                      style="color: red"
                      v-show="!disableDeleteButton[indexOf]"
                    >
                      <a v-if="!forbidDelete" @click="showConfirmDelete(value)"
                        >Delete</a
                      >
                    </span>
                  </small>
              </td> -->

              <td
                v-if="
                  (!!onCancel && !forbidCancel) ||
                  (!!onDetail && !forbidDetail) ||
                  (!!onDetailTL && !forbidDetailTL) ||
                  (!!onDetailTLI && !forbidDetailTLI) ||
                  (!!onBerkas && !forbidBerkas) ||
                  (!!onBerkaskan && !forbidBerkaskan) ||
                  (!!onMoveBerkas && !forbidMoveBerkas) ||
                  (!!onAtasan && !forbidAtasan) ||
                  (!!onEdit && !forbidEdit) ||
                  (!!onLaporan && !forbidLaporan) ||
                  (!!onBatal && !forbidBatal) ||
                  (!!onKonfirmasi && !forbidKonfirmasi) ||
                  (!!onDisposisi && !forbidDisposisi) ||
                  forbidDelete
                "
                class="text-center"
              >

                <button
                  v-if="!!onDetail && !forbidDetail"
                  @click="onDetail(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Detail Data"
                >
                  <i class="mdi mdi-magnify"></i>
                </button>

                <button
                  v-else
                  @click="onDetail(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Detail Data"
                >
                  <i class="mdi mdi-magnify"></i>
                </button>

                <button
                  v-if="value['unitps'] == '1' && !!onBerkas && !forbidBerkas"
                  @click="onBerkas(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Detail Berkas"
                >
                  <i class="mdi mdi-send"></i>
                </button>

                <button
                  v-if="
                    value['ket'] == 1 && !!onBerkaskan && !forbidBerkaskan
                  "
                  @click="onBerkaskan(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Berkaskan Surat"
                >
                  <i class="mdi mdi-folder-lock"></i>
                </button>

                <button
                  v-if="
                    value['unitps'] == '1' &&
                    !!onMoveBerkas &&
                    !forbidMoveBerkas
                  "
                  @click="onMoveBerkas(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Move Berkas"
                >
                  <i class="mdi mdi-folder-move"></i>
                </button>

                <button
                  v-if="
                    value['flag_atasan'] < '1' && !!onAtasan && !forbidAtasan
                  "
                  @click="showConfirmAtasan(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Atasan"
                >
                  <i class="mdi mdi-account-star"></i>
                </button>

                <!-- @click="onAtasan(value)" -->
                <!-- <button
                  v-if="!!onEdit && !forbidEdit && value['is_physical'] == '1' || value['status'] == '' || value['is_superior'] == '0'"
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button> -->

                <button
                  v-if="
                    value['canCancel'] === undefined &&
                    !!onCancel &&
                    !forbidCancel
                  "
                  @click="onCancel(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Cancel Data"
                >
                  <i class="mdi mdi-backup-restore"></i>
                </button>
                <button
                  v-else-if="
                    value['canCancel'] == true && !!onCancel && !forbidCancel
                  "
                  @click="onCancel(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Cancel Data"
                >
                  <i class="mdi mdi-backup-restore"></i>
                </button>
                <button
                  v-if="
                    value['canEdit'] === undefined && !!onEdit && !forbidEdit
                  "
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <button
                  v-if="
                    value['canEdit'] === undefined && !!onLaporan && !forbidLaporan
                  "
                  @click="onLaporan(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <!-- {{ value["canEdit"] }} -->

                <!-- v-if="
                    value['deleteable'] == true && !!onBatal && !forbidBatal
                  " -->
                <button
                  v-else-if="
                    value['canEdit'] == true && !!onEdit && !forbidEdit
                  "
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <button
                  v-else-if="value['canEdit'] == true"
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <!-- <button
                  v-else-if="
                    value['canEdit'] == true && !!onLaporan && !forbidLaporan
                  "
                  @click="onLaporan(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button>
                <button
                  v-else-if="value['canEdit'] == true"
                  @click="onLaporan(value)"
                  class="btn btn-sm btn-success mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button> -->
                <!-- <button
                  v-if="value['deleteable'] == true"
                  @click="onBatal(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Dibatalkan"
                >
                  <i class="fa fa-trash"></i>
                </button> -->
                <!-- <button
                  v-else
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button> -->
                <!-- <button
                  v-if="!!onEdit && !forbidEdit && value['status'] != ''"
                  @click="onEdit(value)"
                  class="btn btn-sm btn-warning mr-1"
                >
                  <i class="mdi mdi-grease-pencil"></i>
                </button> -->

                <button
                  v-if="
                    value['canKonfirmasi'] === undefined &&
                    !!onKonfirmasi &&
                    !forbidEdit
                  "
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button>
                <button
                  v-else-if="
                    value['canKonfirmasi'] == true &&
                    !!onKonfirmasi &&
                    !forbidEdit
                  "
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button>

                <button
                  v-if="
                    value['canDisposisi'] === undefined &&
                    !!onDisposisi &&
                    !forbidEdit
                  "
                  @click="onDisposisi(value)"
                  class="btn btn-sm btn-secondary mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-account"></i>
                </button>
                <button
                  v-else-if="
                    value['canDisposisi'] == true &&
                    !!onDisposisi &&
                    !forbidEdit
                  "
                  @click="onDisposisi(value)"
                  class="btn btn-sm btn-secondary mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-account"></i>
                </button>

                <!-- <button
                  v-if="value['canKonfirmasi']===undefined && (!!onKonfirmasi && !forbidKonfirmasi)"
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button>
                <button
                  v-else-if="value['canKonfirmasi']==true && (!!onKonfirmasi && !forbidKonfirmasi)"
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-warning mr-1"
                  title="Edit Data"
                >
                  <i class="mdi mdi-check"></i>
                </button> -->

                <!-- <button
                  v-if="value['konfirmasi'] != '1' && forbidKonfirmasi"
                  @click="onKonfirmasi(value)"
                  class="btn btn-sm btn-info mr-1"
                  title="Konfirmasi"
                >
                  <i class="mdi mdi-check"></i>
                </button> -->
                <!-- <button
                  v-if="value['konfirmasi'] === '1' && forbidDisposisi"
                  @click="onDisposisi(value)"
                  class="btn btn-sm btn-secondary mr-1"
                  title="Disposisi"
                >
                  <i class="mdi mdi-account"></i>
                </button> -->
                <button
                  v-show="!forbidDelete"
                  :disabled="disableDeleteButton[indexOf]"
                  @click="showConfirmDelete(value)"
                  class="btn btn-sm btn-danger mr-1"
                  title="Hapus Data"
                >
                  <i class="mdi mdi-delete"></i>
                </button>
              </td>
              
              <!-- <td>
                <div v-if="header.type === 'date'">
                  {{ formatDate(value.name) }}
                </div>
                <div v-else-if="header.type === 'dateTime'">
                  {{ formatDateTime(value.name) }}
                </div>
                <div v-else>
                  <span v-html="value[Object.keys(header)[0]]"></span>                  
                </div>
              </td>
              <td><span v-html="value[Object.keys(header)[3]]"></span></td> -->
              <td
                v-for="(header, index) in header"
                :key="index"
                data-toogle="tooltip"
                :title="value[header.tooltip]"
              >
                <div
                  v-if="
                    value['is_read_recipient'] === 1 ||
                    value['is_read_personal'] === 1
                  "
                >
                  {{ header.condition ? haveCondition(indexOf) : null }}
                  <div
                    v-if="
                      header.is_read &&
                      (value['is_read_recipient'] === 1 ||
                        value['is_read_personal'] === 1)
                    "
                  >
                    <input type="checkbox" checked disabled />
                  </div>
                  <div
                    :style="fontStyle"
                    v-bind:class="{ 'bold-td': value.isBold }"
                    v-else
                  >
                    <div v-if="header.type === 'date'">
                      {{ formatDate(value[index]) }}
                    </div>
                    <div v-else-if="header.type === 'dateTime'">
                      {{ formatDateTime(value[index]) }}
                    </div>
                    <div v-else>
                      <span v-html="value[index]"></span>
                    </div>
                  </div>
                </div>
                <div v-else>
                  {{ header.condition ? haveCondition(indexOf) : null }}
                  <div
                    v-if="
                      header.is_read &&
                      (value['is_read_recipient'] === 0 ||
                        value['is_read_personal'] === 0)
                    "
                  >
                    <input type="checkbox" disabled />
                  </div>
                  <div
                    :style="fontStyle"
                    v-bind:class="{ 'bold-td': value.isBold }"
                    v-else
                  >
                    <div v-if="header.type === 'date'">
                      {{ formatDate(value[index]) }}
                    </div>
                    <div v-else-if="header.type === 'dateTime'">
                      {{ formatDateTime(value[index]) }}
                    </div>
                    <div v-else>
                      <span v-html="value[index]"></span>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="items.length === 0">
              <td :colspan="Object.keys(header).length + 2" class="text-center">
                No record found
              </td>
            </tr>
          </template>
        </tbody>
      </table>
      <div class="col-md-12">
        <div class="pull-right">
          <Paginate
            :page="filter.page"
            :totalPage="filter.totalPages"
            @onChangePage="updatePagination"
          />
        </div>
      </div>
    </template>
    <ConfirmDelete
      v-show="isModalVisible"
      @close="closeModal"
      @action="onDelete(deleteData)"
    />
    <ConfirmAtasan
      v-show="isModalVisible2"
      @close="closeModal"
      @action="onAtasan(atasanData)"
    />
  </div>
</template>
<script>
import Paginate from "../paginate/index.vue";
import ConfirmDelete from "../modal/confirmDelete";
import ConfirmAtasan from "../modal/confirmAtasan";
export default {
  components: {
    Paginate,
    ConfirmDelete,
    ConfirmAtasan,
  },
  props: {
    header: {
      required: true,
      type: Object,
    },
    items: {
      required: true,
      type: Array,
    },
    filter: {
      required: true,
      type: Object,
    },
    laporan: {
      required: false,
      type: String,
    },
    onAction: {
      required: false,
      type: Function,
    },
    onDetail: {
      required: false,
      type: Function,
    },
    onApprove: {
      required: false,
      type: Function,
    },
    onBatalPesan: {
      required: false,
      type: Function,
    },
    onDetailTL: {
      required: false,
      type: Function,
    },
    onDetailTLI: {
      required: false,
      type: Function,
    },
    onBerkas: {
      required: false,
      type: Function,
    },
    onBerkaskan: {
      required: false,
      type: Function,
    },
    onMoveBerkas: {
      required: false,
      type: Function,
    },
    onAtasan: {
      required: false,
      type: Function,
    },
    onEdit: {
      required: false,
      type: Function,
    },
    onPemesanan: {
      required: false,
      type: Function,
    },
    onUsul: {
      required: false,
      type: Function,
    },
    onLaporan: {
      required: false,
      type: Function,
    },
    onBatal: {
      required: false,
      type: Function,
    },
    onCancel: {
      required: false,
      type: Function,
    },
    onKonfirmasi: {
      required: false,
      type: Function,
    },
    onDisposisi: {
      required: false,
      type: Function,
    },
    forbidEdit: {
      required: false,
      type: Boolean,
    },
    forbidPemesanan: {
      required: false,
      type: Boolean,
    },
    forbidUsul: {
      required: false,
      type: Boolean,
    },
    forbidLaporan: {
      required: false,
      type: Boolean,
    },
    forbidBatal: {
      required: false,
      type: Boolean,
    },
    forbidKonfirmasi: {
      required: false,
      type: Boolean,
    },
    forbidDisposisi: {
      required: false,
      type: Boolean,
    },
    forbidDelete: {
      required: false,
      type: Boolean,
    },
    forbidCancel: {
      required: false,
      type: Boolean,
    },
    forbidDetail: {
      required: false,
      type: Boolean,
    },
    forbidApprove: {
      required: false,
      type: Boolean,
    },
    forbidBatalPesan: {
      required: false,
      type: Boolean,
    },
    forbidDetailTL: {
      required: false,
      type: Boolean,
    },
    forbidDetailTLI: {
      required: false,
      type: Boolean,
    },
    forbidBerkas: {
      required: false,
      type: Boolean,
    },
    forbidBerkaskan: {
      required: false,
      type: Boolean,
    },
    forbidMoveBerkas: {
      required: false,
      type: Boolean,
    },
    forbidAtasan: {
      required: false,
      type: Boolean,
    },
    overideSearch: {
      required: false,
      type: String,
    },
  },
  data() {
    let stl = { fontWeight: 600 };
    if (
      this.$route.path.includes("disposition") ||
      this.$route.path.includes("suratKeluar") ||
      this.$route.path.includes("documentAlready") ||
      this.$route.path.includes("documentIn") ||
      this.$route.path.includes("documentRejected") ||
      this.$route.path.includes("documentRetract")
    ) {
      stl = {};
    }
    return {
      fontStyle: stl,
      selectLimit: this.filter.limit,
      search: this.overideSearch || "",
      page: "1",
      shortable: "asc",
      isModalVisible: false,
      isModalVisible2: false,
      deleteData: {},
      disableDeleteButton: [],
    };
  },
  methods: {
    doUpdate() {
      const data = {
        limit: this.selectLimit,
        search: this.search,
        page: this.filter.page,
        sortable: this.shortable,
      };
      this.$emit("onSearch", this.search);
      this.onAction(data);
    },
    doUpdateWhenLimit() {
      const data = {
        limit: this.selectLimit,
        search: this.search,
        page: 1,
        sortable: this.shortable,
      };
      this.onAction(data);
    },
    updatePagination(position) {
      const data = {
        limit: this.selectLimit,
        search: this.search,
        page: position,
        sortable: this.shortable,
      };
      this.onAction(data);
    },
    showConfirmDelete(val) {
      this.isModalVisible = true;
      this.deleteData = val;
    },
    showConfirmAtasan(val) {
      this.isModalVisible2 = true;
      this.atasanData = val;
    },
    closeModal() {
      this.isModalVisible = false;
      this.isModalVisible2 = false;
    },
    onDelete() {
      this.$emit("onDelete", this.deleteData);
      this.isModalVisible = false;
    },
    // onAtasan() {
    //   this.$emit("onAtasan", this.atasanData);
    //   this.isModalVisible = false;
    // },
    // eslint-disable-next-line no-unused-vars
    haveCondition(index) {
      this.disableDeleteButton[0] = true;
    },
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    computedItem() {
      let items = this.items;
      let inc = 0;
      for (let i in items) {
        inc += 1;
        items[i].increment =
          inc + (parseInt(this.filter.page) - 1) * parseInt(this.filter.limit);
      }
      return items;
    },
    isContainFile() {
      let isArray = Array.isArray(this.items);
      let response = false;
      if (isArray) {
        if (this.items.length) {
          if ("file" in this.items[0]) {
            response = true;
          }
        }
      } else if (Object.keys(this.items).length) {
        for (var e in this.items) {
          if (this.items[e].file !== undefined) {
            response = true;
            break;
          }
        }
      } else {
        if (this.items.file !== undefined) {
          response = true;
        }
      }
      return response;
    },
    isLaporan() {
      // let response = this.items[0].is_read;
      // let isArray = Array.isArray(this.items);
      // if (isArray) {
      //   if (this.items.length) {
      //     if ("isLaporan" in this.items[0]) {
      //       response = this.items[0].isLaporan;
      //     }
      //   }
      // }
      // else {
      //   response = false;
      // }
      // return isArray;
      let response = false;
      console.log(this.items);
      for (var e in this.items) {
        response = this.items[e].isLaporan;
      }
      return response;
    },
    isAksi() {
      let response = false;
      for (var e in this.items) {
        response = this.items[e].aksi;
      }
      return response;
    },
    isRuangan() {
      let response = true;
      for (var e in this.items) {
        response = this.items[e].isMeeting;
      }
      return response;
    },
    isKlasifikasi() {
      // let response = this.items[0].kecepatan;
      // return response;
      let response = 0;
      for (var e in this.items) {
        response = this.items[e].kecepatan;
        // if (this.items[e].kecepatan === "1") {
        //   response = true;
        //   break;
        // } else if (this.items[e].kecepatan === "2") {
        //   response = true;
        //   break;
        // } else {
        //   response = false;
        //   break;
        // }
      }
      return response;
    },

    // isLaporan() {
    //   // let isArray = Array.isArray(this.items);
    //   let response = false;
    //   response = this.items[0].isLaporan;
    //   // if (isArray) {
    //   //   if (this.items.length) {
    //   //     if ("isLaporan" in this.items[0]) {
    //   //       response = this.items[0].isLaporan;
    //   //     }
    //   //   }
    //   // } else if (Object.keys(this.items).length) {
    //   //   for (var e in this.items) {
    //   //     if (this.items[e].file !== undefined) {
    //   //       response = true;
    //   //       break;
    //   //     }
    //   //   }
    //   // } else {
    //   //   if (this.items.file !== undefined) {
    //   //     response = true;
    //   //   }
    //   // }
    //   return response;
    // },
  },
};
</script>
<style>
.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #31708f;
  border-radius: 0.25rem;
}
</style>

<style>
.bold-td {
  font-weight: 600;
}
</style>